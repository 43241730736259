import React,{useState} from 'react';
import Layout from '../components/layouts/layout';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby"
import Seo from "../components/Seo"

const phoneRegex = /^\(?([0-9]{2,3})\)?[-. ]?([0-9]{3,4})[-. ]?([0-9]{3,4})$/;
const contactFormSchema = yup.object().shape({
    company_name: yup
        .string()
        .required("会社名は入力必須です。"),
    name: yup
        .string()
        .required("お名前は入力必須です。"),
    phone_number: yup
        .string()
        .matches(phoneRegex, '電話番号のフォーマットは無効です')
        .required("電話番号は入力必須です。"),
    email: yup
        .string()
        .email("メールアドレスのフォーマットは無効です。")
        .required("メールアドレスは入力必須です。"),
    query_type: yup
        .array()
        .min(1 ,'お問合せ内容をご選択ください。')
        .of(yup.string().required())
        .required('お問合せ内容をご選択ください。'),
    accept: yup
        .bool()
        .oneOf([true], '送信するためには、チェックが必要です。')
});


const Contact = () => {

    const [sendContact, setSendContact] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        // setError,
        reset,
      } = useForm({
        resolver: yupResolver(contactFormSchema),
        defaultValues: {
            query_type: [],
        },
      });
      async function onSubmit({
        company_name,
        name,
        phone_number,
        email,
        // querys,
        query_type,
        query_content,
      }) {       
        var options = {  
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer p5CrJjaxVndbiZpsGTUH06wCJPXmceOx9JBcarv1`, 
            //   'Authorization': `Bearer DzgI3HVpee2ZhXAgVeiwjkFK75jykp6bBNORGeB2`, 
            },
            body: JSON.stringify({
                'company_name': company_name,
                'name': name,
                'phone_number': phone_number,
                'email':email,
                // 'query':querys,
                'query_type': query_type,
                query_content,
            })
          }
        setIsLoading(true);
        const res =  await fetch(
            `https://app.8life.cloud/api/contact/create`,
            // `http://192.168.1.8:8884/api/contact/create`,
            options
        );
        setIsLoading(false);
        if(res.status === 200){
            reset();
            setSendContact(true);
            navigate("/complete");
        }else{
        }
    }
    const [querys] = useState(
        [
            "サービスの詳細が知りたい" ,
            "価格について詳しく聞きたい" ,
            "資料請求する",
            "その他のお問い合わせ",
        ]
    )
    return (
    <Layout>
        <section id="contact" className='eightlife__bg lg:flex-1'>
            <div className='centralize my-[30px] p-[10px] text-[30px] font-bold text-[#06B6D4]'>
                <h1>お問い合わせ</h1>
            </div>
            <div className='contact'>
                <div className='contact--note'>
                    <p>
                    お問い合わせは、お電話（TEL:03-6262-6157）もしくは下記のお問い合わせフォームにご記入いただきご連絡ください。３営業日以内に担当者よりご連絡致します。
                    </p>
                    {sendContact && (
                        <div>
                        <p className='text-cyan-500 text-center font-bold mt-[25px]'>お問い合わせありがとうございました、メールをお待ち下さい。</p>
                    </div>
                    )}
                </div>
                    {/* ※ */}
                <div className='contact__form'>
                    <form name='contact__form' onSubmit={handleSubmit(onSubmit)} noValidate>
                        {/* COMPANY NAME */}
                        <div className='flex flex-col mb-[25px]'>
                            <label className='require mb-[12.5px]' htmlFor='company_name'> 会社名</label>
                            <input className='text-[15px] xl:text-[18.75px] bg-white lg:pl-[25px]' id='company_name' name="company_name" type='text' placeholder='株式会社サンプル' {...register("company_name")}/>
                            {errors?.company_name?.message && (
                                <label> <span className='text-red-500 text-[15px]'>{ errors?.company_name?.message }</span></label>
                            )}
                        </div>
                        {/* YOUR NAME */}
                        <div className='flex flex-col mb-[25px]'>
                            <label className='require mb-[12.5px]' htmlFor='name'>お名前</label>
                            <input className='text-[15px] xl:text-[18.75px] bg-white ' id='name'  name="name" type='text' placeholder='保険 太郎' {...register("name")}/>
                            {errors?.name?.message && (
                                <label> <span className='text-red-500 text-[15px]'>{ errors?.name?.message }</span></label>
                            )}
                        </div>
                        {/* PHONE NUMBER */}
                        <div className='flex flex-col mb-[25px]'>
                            <label className='require mb-[12.5px]' htmlFor='phone_number'>お電話</label>
                            <input className='text-[15px] xl:text-[18.75px] bg-white' id='phone_number' name="phone_number"type='text' placeholder='00-123-45678' {...register("phone_number")}/>
                            {errors?.phone_number?.message && (
                                <label> <span className='text-red-500 text-[15px]'>{ errors?.phone_number?.message }</span></label>
                            )}
                        </div>
                        {/* MAIL */}
                        <div className='flex flex-col mb-[25px]'>
                            <label className='require mb-[12.5px]' htmlFor='email'>メールアドレス</label>
                            <input className='text-[15px] xl:text-[18.75px] bg-white' id='email' name="email" type='text' placeholder='hokentarowu@hoken.com' {...register("email")}/>
                            {errors?.email?.message && (
                                <label> <span className='text-red-500 text-[15px]'>{ errors?.email?.message }</span></label>
                            )}
                        </div>
                        {/* CONTACT MULTICHOICE */}
                        <div className='flex flex-col mb-[25px]'>
                            <label className=' mb-[12.5px]' htmlFor='querys'>お問い合わせ種別{/*お問い合わせ内容*/}</label>
                            <div className="contact__form--choice">
                                {/* <div className="contact__form--choice-detail text-[15px] xl:text-[18.75px]"> */}
                                    {/* <textarea className='w-full bg-white rounded text-[15px] xl:text-[18.75px]' id="query" placeholder='具体にお知りになりたい内容をご記入ください。' {...register("query")}></textarea>                                       */}
                                    {querys.map((query, idx) => (
                                        <div className='flex' key={idx}>
                                        <div key={idx} className="contact__form--choice-detail ">
                                            <input
                                                name={`query.${idx}`}
                                                type="checkbox"
                                                value={query}
                                                {...register("query_type")}
                                            />
                                        </div>
                                        <span className=" ml-[12.5px] lg:mx-[5px] text-[15px] xl:text-[18.75px]">{query}</span>
                                        </div>
                                    ))}
                                {/* </div> */}
                                {errors?.query_type?.message && (
                                <label> <span className='text-red-500 text-[15px]'>{ errors?.query_type?.message }</span></label>
                                )}
                            </div>
                        </div>
                        {/* INQUIRY CONTENT*/}
                        <div className='flex flex-col mb-[25px]'>
                            <label className='mb-[12.5px]' htmlFor='query_content'>お問い合わせ内容</label>
                            <textarea className='text-[15px] xl:text-[18.75px] bg-white outline-none' id='query_content' name="query_content" type='text' placeholder='お問い合わせ内容' {...register("query_content")}/>
                            {errors?.query_content?.message && (
                                <label> <span className='text-red-500 text-[15px]'>{ errors?.query_content?.message }</span></label>
                            )}
                        </div>
                        {/* TERMS AND CONDITION BUTTON */}
                        <div className='flex flex-col mb-[25px]'>
                            <div className='checkpolicy'>
                                <div className='w-[20px] mr-[12.5px]'>
                                    <input
                                        type="checkbox" 
                                        name="accept"
                                        className='submitbox cursor-pointer text-[15px] xl:text-[18.75px] bg-white rounded'
                                        id="accept"
                                        {...register("accept")}
                                    />
                                </div>
                                <label
                                    htmlFor="accept"
                                    className='
                                        text-[18.75px] flex flex-wrap
                                    '>
                                    <a
                                        className='text-cyan-800 hover:underline underline-offset-4 transition'
                                        href="https://www.joa-holdings.com/personal-info"
                                        target="_blank"
                                        rel="noreferrer">
                                        個人情報の取扱いに関する告知
                                    </a>
                                    <span>・</span>
                                    <a
                                        className='text-cyan-800 hover:underline underline-offset-4 transition'
                                        href="https://www.joa-holdings.com/privacy-policy">
                                        プライバシーポリシー
                                    </a>
                                    <span>をお読みの上、同意して送信して下さい。</span>
                                </label>
                            </div>
                            {errors?.accept?.message && (
                                <label>
                                    <span className='text-red-500 text-[15px]'>{ errors?.accept?.message }</span></label>
                            )}
                        </div>
                        {/* 送信中 BUTTON */}
                        <button  className=' lg:w-[360px] w-full centralize mx-auto text-[18.75px] mt-[10px] bg-cyan-500 text-cyan-50 py-[15px] hover:bg-cyan-600 transition-colors duration-200 rounded' type="submit" disabled={isLoading}>
                            { isLoading ?
                                (
                                <span className='inline-flex items-center'>
                                    <svg role="status" className="inline mr-[7.5px] w-[20px] h-[20px] text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                    </svg>送信中
                                </span>
                                )
                                : (
                                    <span className=''> 送信する</span>
                                )
                            }
                        </button>
                    </form>
                </div>
            </div>
        </section>
    </Layout>
  )
}
export default Contact

export const Head = () => (
    <Seo 
        title="お問い合わせ | 8LIFE"
        description = "８Lifeに関するお問い合わせはこちらからどうぞ。"
    />
);